import React, { useState, useEffect, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Layout from "layout/Layout"
import Message from "elements/Message"
import Container from "layout/Container"
import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"
import CompanyId from "./CompanyId"

import { hasRoles } from "../Auth/services/user"
import { AppContext } from "../../context/AppContext"
import { isObjectEmpty } from "services/general"

import { useMentalHealthFormFields } from "./hooks/useAirtableMHFormFields"
import { generateFormField } from "elements/Form/services/form"
import { generateInitialValues } from "../../services/context"
import { isPersonalEmail } from "./utils/isPersonalEmail"

const Enrollment = (props) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  let { pageContext } = props

  const handleSubmit = async (values) => {
    setLoading(true)

    await dispatch({
      type: "SAVE_ENROLLMENT",
      payload: {
        ...values,
      },
    })

    navigate("/enrollment/summary")
  }

  const [isPersonal, setIsPersonal] = useState(false)

  const idFront = state?.documents?.find((document) =>
    document?.name?.startsWith("id_front")
  )
  const idBack = state?.documents?.find((document) =>
    document?.name?.startsWith("id_back")
  )

  useEffect(() => {
    if (hasRoles()) navigate("/profile")

    let isPersonal = isPersonalEmail()
    setIsPersonal(isPersonal)
    dispatch({
      type: "GET_CONTEXT_FROM_SESSION"
    })
  }, [])

  let formConditions = {
    isPersonalEmail: isPersonalEmail(),
    isWorkEmail: !isPersonalEmail(),
  }

  let formFields = pageContext.formFields.filter((field) => {
    if (field.conditionFieldName) {
      return formConditions[field.conditionFieldName]
    } else return true
  })

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields,
    hasId: isPersonalEmail(),
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...state?.enrollment,
    id: { front: idFront, back: idBack },
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      display={{ footer: false, helpCenterBanner: false }}
      isPrivate
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...initialValues, ...state?.enrollment }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, submitCount }) => (
            <Form>
              {sectionFormFields
                .sort(
                  (firstFormField, secondFormField) =>
                    firstFormField.order - secondFormField.order
                )
                .map((section) => (
                  <Fragment>
                    <Section
                      title={section?.section}
                      subtitle={section?.subtitle || ""}
                      id={section?.sectionId || ""}
                    >
                      {section?.message && (
                        <Message color="light">{section?.message}</Message>
                      )}
                      {section?.fields.map((field) => {
                        if (!field?.referenceAnswer) {
                          return (
                            <Fragment>
                              {generateFormField({
                                formFields: section?.fields,
                                formField: field,
                                values,
                                setFieldValue,
                              })}
                              {!!field?.addDividerAfterField && (
                                <hr className="has-background-light" />
                              )}
                            </Fragment>
                          )
                        }
                        return null
                      })}
                    </Section>
                    {isPersonal &&
                      section?.sectionId === "personal-information" &&
                      section?.section === "Employee Details" && (
                        <CompanyId
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          submitCount={submitCount}
                        />
                      )}
                  </Fragment>
                ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{ label: "Continue", loading }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Enrollment
